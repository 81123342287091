import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Italic from '@tiptap/extension-italic';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';

import Gapcursor from '@tiptap/extension-gapcursor';
import Dropcursor from '@tiptap/extension-dropcursor';
import UniqueID from '@tiptap-pro/extension-unique-id';

import Link from '@tiptap/extension-link';
import HardBreak from '@tiptap/extension-hard-break';

import ListItem from '@tiptap/extension-list-item';

import TableRow from '@tiptap/extension-table-row';
import Highlight from '@tiptap/extension-highlight';

import Mathematics from '@tiptap-pro/extension-mathematics';
import { LinkBubbleMenuHandler, TableImproved } from 'mui-tiptap';
import Collaboration, { isChangeOrigin } from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { IdTokenClaims } from 'oidc-client-ts';
import { GuideLinkExtensionRenderOptions, createGuideLinkExtension } from './GuideLink';
import { CollaborationUser } from '../types/collaborationUser';
import { getFullname, getInitials } from '../../user';
import { createImageFigureExtension } from './ImageFigure';
import { Extensions } from '@tiptap/core';
import { NodeViewProps } from '@tiptap/react';
import { FC } from 'react';
import { InternalLinkExtensionRenderOptions, createInternalLinkExtension } from './InternalLink';
import { createTableFigureExtension } from './TableFigure';
import { Figcaption } from './Figcaption';
import { createHeadingExtension } from './Heading';
import { createAppExtension } from './App';
import { createChartExtension } from './Chart';
import { createChartFigureExtension } from './ChartFigure';
import { CustomTableCell, CustomTableHeader } from './TableCell';
import { createMathTypeExtension } from './MathType';

const colors = [
  '#958DF1',
  '#F98181',
  '#FBBC88',
  '#FAF594',
  '#70CFF8',
  '#94FADB',
  '#B9F18D',
  '#568259',
  '#A4A8D1',
  '#E6AF2E',
  '#406E8E',
  '#D5C67A',
  '#B4436C',
  '#5FAD56',
  '#4D9078',
];

const getRandomElement = <T>(list: T[]) => list[Math.floor(Math.random() * list.length)];

const getRandomColor = () => getRandomElement(colors);

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

const CustomSubscript = Subscript.extend({
  excludes: 'superscript',
});
const CustomSuperscript = Superscript.extend({
  excludes: 'subscript',
});

interface ExtensionRenderOptions {
  appView?: FC<NodeViewProps>;
  imageFigureView?: FC<NodeViewProps>;
  tableFigureView?: FC<NodeViewProps>;
  headingView?: FC<NodeViewProps>;
  guideLink?: GuideLinkExtensionRenderOptions;
  internalLink?: InternalLinkExtensionRenderOptions;
  chartFigureView?: FC<NodeViewProps>;
  chartView?: FC<NodeViewProps>;
  mathTypeView?: FC<NodeViewProps>;
}

const createDefaultExtensions = (renderOptions?: ExtensionRenderOptions): Extensions => [
  Document,
  Text,
  Italic,
  Bold,
  Underline,
  Highlight,
  Gapcursor,
  Dropcursor.configure({
    width: 3,
    color: '#3B86CB',
  }),
  HardBreak,
  ListItem,
  TextAlign.configure({
    types: ['paragraph'],
  }),
  BulletList,
  OrderedList,
  Paragraph,
  createHeadingExtension(renderOptions?.headingView),
  createGuideLinkExtension(renderOptions?.guideLink ?? {}),
  createImageFigureExtension(renderOptions?.imageFigureView),
  createAppExtension(renderOptions?.appView),
  createChartExtension(renderOptions?.chartView),
  createChartFigureExtension(renderOptions?.chartFigureView),
  TableImproved.configure({
    resizable: true,
  }),
  createTableFigureExtension(renderOptions?.tableFigureView),
  createInternalLinkExtension(renderOptions?.internalLink ?? {}),
  createMathTypeExtension(renderOptions?.mathTypeView),
  CustomTableHeader,
  CustomTableCell,
  TableRow,
  Figcaption,
  CustomSubscript,
  CustomSuperscript,
  Mathematics,
  CustomLinkExtension.configure({
    autolink: true,
    linkOnPaste: true,
    openOnClick: false,
  }),
  LinkBubbleMenuHandler,
  UniqueID.configure({
    attributeName: 'id',
    types: ['paragraph', 'heading', 'tableFigure', 'imageFigure', 'chartFigure', 'bulletList', 'orderedList'],
    filterTransaction: (transaction) => {
      // Filter out transactions that should not generate new IDs
      // - When the setContent command is called (meta.preventUpdate: true), as this will already have IDs
      // - When the transaction is from another user in collab mode
      return !transaction.getMeta('preventUpdate') && !isChangeOrigin(transaction);
    },
  }),
];

export const getExtensions = (options?: ExtensionRenderOptions, profile?: IdTokenClaims, provider?: TiptapCollabProvider): Extensions => {
  if (!provider) {
    return createDefaultExtensions(options);
  }
  const user: CollaborationUser = {
    id: profile?.sub!,
    name: getFullname(profile),
    initials: getInitials(profile),
    color: getRandomColor(),
  };
  const collabExtensions = [
    Collaboration.configure({
      document: provider.document,
    }),
    CollaborationCursor.configure({
      provider: provider,
      user,
    }),
  ];
  return [...createDefaultExtensions(options), ...collabExtensions];
};

export default getExtensions;
