import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { Grid, List, ListItem, ListItemText, Typography, IconButton, Collapse, Button, Stack, FormControlLabel, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Uploader } from './Uploader';
import { NoticesForGuide } from '../notices/NoticesForGuide';
import { Standards } from './Standards';
import { Paragraphs } from './Paragraphs';
import { GuideLinks } from './GuideLinks';
import { ListLabel, StyledLink } from '../Components';
import { useMutation } from '@apollo/client';
import { ADD_PARTNER, REMOVE_PARTNER, UPDATE_RELEASENOTES, USE_CMS_CONTENT } from './guide.graphql';
import { PartnerSelectDialog2 } from '../partners/PartnerSelect';
import { Categories } from './Categories';
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import { EditProps, selectGuideEdit, toggleEditMode } from './editSlice';
import { EditableTextField } from '../components/EditableTextField';
import { useFeatureFlags } from '../unleash';
import { InfoBox as GuideCmsInfoBox } from './cms/InfoBox';
import { Guide } from './Details';
import { StyledPaper as ThemedPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { UpdateGuideTitleDialog } from './UpdateGuideTitleDialog';

const PREFIX = 'Edit';

const classes = {
  centerButton: `${PREFIX}-centerButton`,
  container: `${PREFIX}-container`,
  expandButton: `${PREFIX}-expandButton`,
};

const StyledPaper = styled(ThemedPaper)(() => ({
  [`& .${classes.centerButton}`]: {
    textAlign: 'center',
    marginTop: 10,
  },

  [`&.${classes.container}`]: {
    backgroundColor: '#28789133',
  },

  [`& .${classes.expandButton}`]: {
    marginLeft: 20,
  },
}));

export function GuideEdit({ guide }: { guide: Guide }) {
  const dispatch = useDispatch();
  const { guidesInEditMode }: EditProps = useSelector(selectGuideEdit);
  const editMode = guidesInEditMode.includes(guide!.id);
  const toggleShow = () => dispatch(toggleEditMode(guide!.id));

  return (
    <Collapse in={editMode} collapsedSize={78}>
      <StyledPaper variant="outlined" className={classes.container}>
        <Typography variant="h5" gutterBottom={editMode}>
          Innholdspublisering
          {!editMode && (
            <Button className={classes.expandButton} color="primary" title="Vis innhold" onClick={toggleShow} endIcon={<ExpandMore />}>
              Vis mer
            </Button>
          )}
          {editMode && (
            <Button className={classes.expandButton} title="Skjul innhold" onClick={toggleShow} endIcon={<ExpandLess />}>
              Vis mindre
            </Button>
          )}
        </Typography>
        {editMode && <Content guide={guide} />}
        {editMode && (
          <div className={classes.centerButton}>
            <IconButton title="Skjul innhold" onClick={toggleShow} size="large">
              <ExpandLess />
            </IconButton>
          </div>
        )}
      </StyledPaper>
    </Collapse>
  );
}

function Content({ guide }: { guide: Guide }) {
  const { guideCms } = useFeatureFlags();
  const [useCmsContent, { loading }] = useMutation(USE_CMS_CONTENT);
  const elevation = 1;
  if (!guide) return null;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Heading guide={guide} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        <ReleaseNotes guide={guide} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        <NoticesForGuide guideId={guide.id} notices={guide.notices} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        {guideCms && (
          <FormControlLabel
            control={
              <Switch
                checked={guide.useCmsContent}
                disabled={loading}
                onChange={(_, checked) => useCmsContent({ variables: { input: { id: guide.id, useCms: checked } } })}
              />
            }
            label="Bruk innhold fra CMS"
          />
        )}
        {!guide.useCmsContent && <Uploader guide={guide} elevation={elevation} />}
        {guide.useCmsContent && <GuideCmsInfoBox guide={guide} />}
      </Grid>
      <Grid item xs={12}>
        <GuideLinks guideId={guide.id} guides={guide.guideLinks!} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        <Paragraphs guideId={guide.id} paragraphs={guide.paragraphs!} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        <Standards guideId={guide.id} standards={guide.standards!} elevation={elevation} />
      </Grid>
      <Grid item xs={12}>
        <Categories guideId={guide.id} guideMainCategoryId={guide.mainCategory?.id} categories={guide.categories!} elevation={elevation} />
      </Grid>
    </Grid>
  );
}

function Heading({ guide, elevation }: { guide: Guide; elevation?: number }) {
  const [open, setOpen] = useState(false);
  const [addPartner] = useMutation(ADD_PARTNER);
  const [removePartner] = useMutation(REMOVE_PARTNER);
  if (!guide) return null;
  const updatePartner = (partnerId: string | undefined) => {
    if (partnerId) addPartner({ variables: { input: { id: guide.id, partnerId } } });
    else removePartner({ variables: { input: { id: guide.id, partnerId: guide.partner?.id } } });
  };
  const modalEdit = useModal<Guide, unknown>({ data: guide });
  const onEdit = async () => await modalEdit.open(guide);

  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6">Anvisningstittel</Typography>
      <List dense>
        <ListItem>
          <ListLabel>Tittel</ListLabel>
          <ListItemText>
            <Grid container alignItems="center">
              <span style={{ whiteSpace: 'pre-line' }} onClick={onEdit}>
                {guide.docTitle}
              </span>
              <IconButton onClick={onEdit} style={{ padding: 5, marginLeft: guide.docTitle ? 16 : 0 }} size="large">
                <Edit fontSize="small" />
              </IconButton>
            </Grid>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListLabel>Partner</ListLabel>
          <ListItemText>
            <Stack direction="row">
              {guide.partner && <StyledLink to={`/partner/${guide.partner.id}`}>{guide.partner.name}</StyledLink>}
              <IconButton
                onClick={() => setOpen(true)}
                style={{ padding: 5, marginLeft: guide.partner ? 16 : 0 }}
                title="Legg til partner"
                size="large">
                <Edit fontSize="small" />
              </IconButton>
              {open && <PartnerSelectDialog2 selected={guide.partner?.id} onClose={() => setOpen(false)} onSubmit={updatePartner} />}
            </Stack>
          </ListItemText>
        </ListItem>
      </List>
      {modalEdit.isOpen && <UpdateGuideTitleDialog modal={modalEdit} />}
    </StyledPaper>
  );
}

function ReleaseNotes({ guide, elevation }: { guide: Guide; elevation?: number }) {
  const [update] = useMutation(UPDATE_RELEASENOTES);
  if (!guide) return null;
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6">Hva er endret (til endringshistorikk)</Typography>
      <EditableTextField
        value={guide.releaseNotes ?? ''}
        onUpdate={async (value) => {
          await update({ variables: { input: { id: guide.id, text: value } } });
        }}
        TextFieldProps={{
          placeholder: 'Endringsmelding til kunden',
          multiline: true,
          rows: 10,
        }}
      />
    </StyledPaper>
  );
}
