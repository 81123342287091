import { Content, useEditor } from '@tiptap/react';
import { Editor } from '@tiptap/core';
import { useCallback, useEffect } from 'react';
import { useHocuspocus } from '../hocuspocus/useHocuspocus';
import { useAuth } from 'oidc-react';
import { getExtensions } from '../../../../shared/guide-cms/extensions';
import { ImageFigureView } from './extensions/views/ImageFigureView';
import { getGuideLinkExtensionRenderOptions } from './extensions/views/GuideLinkView';
import { getInternalLinkExtensionRenderOptions } from './extensions/views/InternalLinkView';
import { TableFigureView } from './extensions/views/TableFigureView';
import { useUpdateNumberedNodesOnEditor } from './numbering';
import { HeadingView } from './extensions/views/HeadingView';
import { debounce } from 'throttle-debounce';
import { AppView } from './extensions/views/AppView';
import { ChartFigureView } from './extensions/views/ChartFigureView';
import { ChartView } from './extensions/views/ChartView';
import { MathTypeView } from './extensions/views/MathTypeView';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { IdTokenClaims } from 'oidc-client-ts';
import { useUpdateTableOfContentsOnEditor } from '../sidebar/tableOfContents/useTableOfContents';

interface GuideEditorProps {
  guideId: string;
  onEditorCreated?: (editor: Editor) => void;
}

interface ReadOnlyGuideEditorProps {
  content?: Content;
  onEditorCreated?: (editor: Editor) => void;
}

const getGuideEditorExtensions = (profile?: IdTokenClaims, provider?: TiptapCollabProvider) => {
  const guideLinkExtensionRenderOptions = getGuideLinkExtensionRenderOptions();
  const internalLinkExtensionRenderOptions = getInternalLinkExtensionRenderOptions();

  return getExtensions(
    {
      appView: AppView,
      imageFigureView: ImageFigureView,
      tableFigureView: TableFigureView,
      headingView: HeadingView,
      guideLink: guideLinkExtensionRenderOptions,
      internalLink: internalLinkExtensionRenderOptions,
      chartFigureView: ChartFigureView,
      chartView: ChartView,
      mathTypeView: MathTypeView,
    },
    profile,
    provider,
  );
};

export const useReadOnlyGuideEditor = ({ content, onEditorCreated }: ReadOnlyGuideEditorProps) => {
  const extensions = getGuideEditorExtensions();
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();
  const updateTableOfContentsOnEditor = useUpdateTableOfContentsOnEditor();
  const editor = useEditor({
    editable: false,
    extensions,
    onDestroy() {},
    onCreate(props) {
      onEditorCreated?.(props.editor);
    },
    shouldRerenderOnTransaction: false,
  });
  useEffect(() => {
    if (content) {
      setTimeout(() => {
        if (editor && !editor.isDestroyed) {
          editor.commands.setContent(content);
          updateNumberedNodesOnEditor(editor);
          updateTableOfContentsOnEditor(editor);
        }
      });
    }
  }, [editor, content]);

  return {
    editor,
  };
};

export const useGuideEditor = ({ guideId, onEditorCreated }: GuideEditorProps) => {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const hocuspocus = useHocuspocus(guideId);
  const extensions = getGuideEditorExtensions(profile, hocuspocus.provider);
  const updateNumberedNodesOnEditor = useUpdateNumberedNodesOnEditor();
  const updateTableOfContentsOnEditor = useUpdateTableOfContentsOnEditor();

  const updatedTocAndNumberingDebounced = useCallback(
    debounce(300, (editor: Editor) => {
      setTimeout(() => {
        updateNumberedNodesOnEditor(editor);
        updateTableOfContentsOnEditor(editor);
      });
    }),
    [],
  );

  const editor = useEditor(
    {
      editable: true,
      extensions,
      onCreate(props) {
        onEditorCreated?.(props.editor);
      },
      onUpdate(props) {
        updatedTocAndNumberingDebounced(props.editor);
      },
      onDestroy() {},
    },
    [hocuspocus.provider],
  );

  return {
    editor,
    hocuspocus,
  };
};
